/* src/index.css */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
  }
  
  .App {
    text-align: center;
    margin-top: 50px;
  }
  