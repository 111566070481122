* {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
  }
  
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    /* Prevent horizontal scrolling */
  }
  
body {
    background: linear-gradient(to bottom, #01395E 0%, #D7CDC1 100%);
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  
  /* Header */
.header {
    width: 100%;
    height: 110px;
    padding: 0 50px;
    background: #01395E;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    /* relative vs. fixed header */
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
.logo {
    font-size: 20px;
    color: #D1E6F7;
    text-decoration: none;
    font-weight: bold;
  }
  
.logo-text {
    display: block;
  }
  
.navbar {
    display: flex;
    gap: 50px;
    padding-left: 120px;
  }
  
.navbar a {
    position: relative;
    font-size: 20px;
    color: #D1E6F7;
    font-weight: 500;
    text-decoration: none;
  }
  
.navbar a::before {
    content: '';
    position: absolute;
    bottom: 3px;
    left: 0;
    width: 0;
    height: 2px;
    background: #D1E6F7;
    transition: .3s;
  }
  
.navbar a:hover::before {
    width: 100%;
  }
  
.link {
    font-size: 20px;
    color: #D1E6F7;
    text-decoration: none;
    font-weight: 500;
  }
  
.description-container {
    width: 70%;
    margin: 0 auto;
    padding: 30px;
    text-align: center;
  }

.description {
    max-width: 1000px;
    margin: 0 auto;
    text-align: left; /* Aligns text to the left */
    line-height: 1.6;
    font-family: 'Inter', sans-serif;
}

.description h2 {
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 20px;
}

.description h4 {
    font-weight: 500;
    font-size: 1.1em;
    margin-top: 10px;
}

.description p {
    margin-top: 10px;
    font-weight: 400;
    font-size: 1em;
}

.description ul {
    margin-top: 10px;
    padding-left: 20px; /* Indents list items */
}

/* Footer */
.navigation-footer {
    width: 100%;
    padding: 20px 50px;
    background: #01395E;
    box-sizing: border-box;
    margin-top: auto;
    /* Push footer to the bottom */
}

.divider {
    border-top: 1px solid #D1E6F7;
    margin-bottom: 20px;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #D1E6F7;
    font-size: 14px;
}

.footer-links {
    display: flex;
    gap: 20px;
}

.footer-links a {
    color: #D1E6F7;
    font-size: 14px;
    text-decoration: none;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .navbar {
    gap: 20px;
    padding-left: 0;
    }
}

@media (max-width: 768px) {
    .header {
    flex-direction: column;
    height: auto;
    padding: 20px;
    }

    .navbar {
    flex-direction: column;
    gap: 10px;
    }

    .main-container {
    width: 100%;
    }

    .heading-container {
    width: 100%;
    }
}