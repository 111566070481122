* {
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
  }
  
  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    /* Prevent horizontal scrolling */
  }
  
  body {
    background: linear-gradient(to bottom, #01395E 0%, #D7CDC1 100%);
    font-family: 'Inter', sans-serif;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  
  /* Header */
  .header {
    width: 100%;
    height: 110px;
    padding: 0 50px;
    background: #01395E;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    /* relative vs. fixed header */
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .logo {
    font-size: 20px;
    color: #D1E6F7;
    text-decoration: none;
    font-weight: bold;
  }
  
  .logo-text {
    display: block;
  }
  
  .navbar {
    display: flex;
    gap: 50px;
    padding-left: 120px;
  }
  
  .navbar a {
    position: relative;
    font-size: 20px;
    color: #D1E6F7;
    font-weight: 500;
    text-decoration: none;
  }
  
  .navbar a::before {
    content: '';
    position: absolute;
    bottom: 3px;
    left: 0;
    width: 0;
    height: 2px;
    background: #D1E6F7;
    transition: .3s;
  }
  
  .navbar a:hover::before {
    width: 100%;
  }
  
  .link {
    font-size: 20px;
    color: #D1E6F7;
    text-decoration: none;
    font-weight: 500;
  }
  
  .get-involved {
    position: relative;
    width: 800px;
    height: 70px;
    font-weight: bold;
    font-size: 64px;
    line-height: 77px;
    text-align: center;
    color: #DDDDDD;
    margin: 0 auto;
    margin-top: 100px;
  }
  
  .description-container {
    width: 70%;
    margin: 0 auto;
    padding: 30px;
    text-align: center;
  }
  
  .description {
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    margin-top: 10px;
    color: #D1E6F7;
  }

  .action-cards {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    margin: 20px 50px;
    margin-bottom: 130px;
  }
  /*
  .action-card {
    flex: 1;
    text-align: center;
    padding: 20px;
    background-color: #DDDDDD;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .action-card select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  */

  .action-card {
    flex: 1;
    text-align: center;
    padding: 30px; 
    background-color: #ffffff;
    border: 1px solid #ccc;
    border-radius: 15px; 
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
    transition: transform 0.2s, box-shadow 0.2s;
}

.action-card:hover {
    transform: translateY(-5px); 
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2); 
}

.action-card h3 {
    font-size: 20px;
    color: #01395E; 
    margin-bottom: 15px;
}

.action-card select {
    width: 100%;
    padding: 15px; 
    font-size: 16px;
    border: 1px solid #01395E; 
    border-radius: 8px;
    background-color: #f7f7f7; 
    appearance: none;
    position: relative;
    outline: none;
}

.action-card select:focus {
    border-color: #01395E;
    box-shadow: 0 0 5px rgba(1, 57, 94, 0.3); 
}

/* Container for the select dropdown */
.select-container {
  position: relative;
  width: 100%;
}

.action-card select {
  width: 100%;
  padding: 15px;
  font-size: 16px;
  font-family: 'Inter', sans-serif; /* Match the rest of the site’s font */
  font-weight: 500; /* Set weight to match other text */
  color: #01395E; /* Set text color to match theme */
  border: 1px solid #01395E;
  border-radius: 8px;
  background-color: #f7f7f7;
  appearance: none;
  background: none;
  outline: none;
}

/* Custom dropdown arrow */
.select-container::after {
  content: '▼';
  font-size: 14px;
  color: #01395E;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

  
  /* Footer */
  .navigation-footer {
    width: 100%;
    padding: 20px 50px;
    background: #01395E;
    box-sizing: border-box;
    margin-top: auto;
    /* Push footer to the bottom */
  }
  
  .divider {
    border-top: 1px solid #D1E6F7;
    margin-bottom: 20px;
  }
  
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #D1E6F7;
    font-size: 14px;
  }
  
  .footer-links {
    display: flex;
    gap: 20px;
  }
  
  .footer-links a {
    color: #D1E6F7;
    font-size: 14px;
    text-decoration: none;
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .navbar {
      gap: 20px;
      padding-left: 0;
    }
  }
  
  @media (max-width: 768px) {
    .header {
      flex-direction: column;
      height: auto;
      padding: 20px;
    }
  
    .navbar {
      flex-direction: column;
      gap: 10px;
    }
  
    .main-container {
      width: 100%;
    }
  
    .heading-container {
      width: 100%;
    }
  }